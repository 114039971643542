.mainContainer{
    height: 30px;
    display: flex;
    font-size: 17px;
    font-weight: 900;
    filter: drop-shadow(5px 5px 0.4rem rgba(0, 0, 0,0.6));
    user-select: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 2000;
    border-radius: 5px;
    background-color: white;
    padding: 5px 10px 5px 10px;
}