.mainContainer{
    display: none;
    opacity: 1;
}

.notScrolling {
	overflow: hidden;
}

.scrolling {
	overflow: scroll;
}

@media (max-width: 720px) {
    .mainContainer{
        display: flex;
    }
    .backGround{
        position: fixed;
        display: block;
        width: 100vw;
        height: 100vh;
        background-color: black;
        opacity: 0.2;
        z-index: 30;
    }

    .popUpContainer{
        width: 70vw;
        height: min-content;
        background-color: var(--specialProductsBackground);
        position: fixed;
        z-index: 31;
        left: 15vw;
        top: 20vh;
        filter: drop-shadow(0px 0px 0.7rem black);
        border-radius: 30px;
        padding: 20px;
        overflow: hidden;
    }
    .closeButton{
        position: absolute;
        font-size: 25px;
        width: 50px;
        height: 50px;
        right: 10px;
        font-weight: 800;
        border-radius: 100%;
        z-index: 35;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
    }
    .portadaImg{
        position: absolute;
        top: 0;
        left: 0;
        clip-path: ellipse(147% 100% at 27% 0%);
        height: 30vh;
        width: 100%;
        background-image: url("../../assets/images/Resumatio_ImagenPrincipal.jpg");
        background-size: 200% 30vh;
        background-position: 50%;
        background-repeat: no-repeat;
    }
    .info{
        margin-top: 28vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
    }
    .title{
        font-size: 26px;
        font-weight: 700;
        color: var(--principalOrange);
        margin-bottom: 20px;
        margin-top: 10px;
    }
    .infoTagContainer{
        display: grid;
    }
    .infoTag{
        font-size: 20px;
        font-weight: 500;
    }
    .plus{
        font-size: 20px;
        font-weight: 700;
        color: var(--principalOrange);
    }
}