.mainContainer {
	display: grid;
	justify-items: center;
	margin-top: 50px;
	justify-content: center;
	background-image: url("../../assets/images/ServicesBackground.jpg");
	padding-top: 70px;
	background-repeat: no-repeat;
	background-size: contain;
}
.title {
	font-size: 40px;
	font-weight: 800;
}
.cardsContainer {
	display: flex;
	position: relative;
	margin-top: 40px;
	gap: 2.5vw;
	padding: 0 1.5vw 0 1.5vw;
}
.aditionalInfo {
	margin-top: 30px;
	font-size: 22px;
	font-weight: 450;
}

@media (max-width: 720px) {
	.cardsContainer {
		gap: 40px;
		padding: 0 10px 0 10px;
		display: grid;

	}
	.mainContainer{
		background-image: none;
		margin: 0;
	}
	.title{
		color: var(--principalOrange);
	}
	.aditionalInfo{
		padding: 0 20px 0 20px;
	}
}
@media (min-width: 1400px) {
	.mainContainer {
		background-position-y: -5vw;
	}
}

@media (min-width: 1820px) {
	.mainContainer {
		background-position-y: -6vw;
		background-size: cover;
	}
}
