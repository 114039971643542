.background{
    padding-top: 70px;
    background-color: var(--lightOrange);
}
.mainContainer{
    display: grid;
    justify-items: center;
    padding: 10px 170px 30px 170px;
    background: rgb(143,47,5);
    background: linear-gradient(90deg, rgba(143,47,5,1) 0%, rgba(255,116,0,1) 42%);
    border-radius: 70px 0 0 0;

}
.title, .titleMobile{
    font-size: 40px;
    font-weight: 800;
    margin-bottom: 10px;
}
.info{
    font-size: 23px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
}
.titleMobile{
    display: none;
}

@media (max-width: 720px) {
    .mainContainer{
        padding: 30px 20px 30px 20px;
        margin-top: 0px;
    }
    .title{
        display: none;
    }
    .titleMobile{
        margin-bottom: 20px;
        font-size: 30px;
        color: orange;
        text-align: center;
    }
    .info{
        font-size: 18px;
        font-weight: 800;
        padding: 10px 10px 10px 10px;
        color: var(--principalWhite);
    }
    .titleMobile{
        display: block;
    }
}