.mainContainer{
    display: grid;
    filter: drop-shadow(5px 5px 0.4rem black);
    width: 40vw;
    background-color: white;
    justify-content: center;
    text-align: center;
    border-radius: 30px 0 0 0;
    padding: 20px 0 20px 0;
    min-width: 310px;

}
.title{
    font-size: 27px;
    font-weight: 700;
}
.hyperVincule{
    font-size: 23px;
    line-height: 30px;
    font-weight: 500;
    cursor: pointer;
}
.hyperVincule:hover{
    text-decoration: underline;
}

@media (max-width: 720px) {
    .hyperVincule{
        font-size: 20px;
    }
    .mainContainer{
        width: 100%;
        min-width: 0px;
        padding: 20px 10px 20px 10px ;
    }
}
@media (max-width: 380px) {
    .title{
        padding-bottom: 10px;
    }
    .hyperVincule{
        line-height: 20px;
        font-size: 16px;
    }
}