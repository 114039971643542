.textsContainer {
	margin-top: 40px;
	display: grid;
	padding: 0 0 0 var(--smallPadding);
	width: 100%;
	min-width: calc(var(--minWidthResolution) * 0.6);
}

.title,
.subTitle {
	color: var(--principalBlack);
	text-align: center;
}
.title {
	font-size: 55px;
	font-weight: 900;
}
.subTitle {
	margin-top: 8px;
	font-size: 30px;
}

@media (max-width: 720px) {
	.textsContainer {
		min-width: 0px;
		padding: 0 20px 0 20px;
		margin-top: 90px;
		gap: 5px;
		justify-content: center;
		justify-items: center;
	}
	.title{
		font-size: 35px;
		color: var(--principalWhite);
		text-align: left;
	}
	.subTitle{
		font-size: 20px;
		text-align: left;

		color: var(--principalWhite);
	}
	
}
