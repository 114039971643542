.mainContainer{
	display: none;
}
@media (max-width: 720px) {


	.mainContainer {
		position: relative;
		height: 100vh;
		width: 100%;
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}
	.portada{
		height: 50vh;
		width: 130vw;
		max-width: none;
		position: absolute
	}
	.principalImage{
		height: calc(78vh);
		top: 22vh;
		position: absolute;
		width: 100vw;
		background-image: url(../../../assets/images/Resumatio_ImagenPrincipal.jpg);
		background-repeat: no-repeat;
		background-position: bottom;
		background-size: auto 78vh;
	}
	.presentationTexts{
		z-index: 1;
	}
	.mobileNavBar{
		position: absolute;
		bottom: 0px;
		width: calc(100vw - 20px);
		margin: 10px 10px 20px 10px ;
		padding: 10px  ;
		background-color: black;
		color: var(--principalWhite);
		border-radius: 1000px;
		display: flex;
		justify-content: space-around;
		align-items: center;
		align-content: center;

	}
	.navBarButtonTouched{
		color: var(--principalOrange);
	}
}