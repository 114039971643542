body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
html, body, .root{
  min-width: var(--minWidthResolution)

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

:root{
  --principalBlack: #001524;
  --principalGrey: #efefef;
  --arrowGrey: #878787;
  --principalWhite: #FFE8CB;
  --lightOrange: #fde1c6;
  --specialProductsBackground: #fef9f3;
  --orange100: #f3e3dc;
  --orange200: #f7b688;
  --principalOrange: #FF7400;
  --principalRed: #812200;
  --succesGreen: #45b55c;
  --errorRed: #FF333F;
  --blueResumatio: #011824;
  --bigPadding: 200px;
  --midPadding: 100px;
  --smallPadding: 50px;
  --normalTransitionDuration: 300ms;
  --popUpTransitionDuration: 500ms;
  --minWidthResolution : 720px;
}


@font-face {
  font-family: 'Principal';
  src: url(./assets/fonts/RedHatDisplay-VariableFont_wght.ttf);
}

*{
  font-family: 'Principal';
  transition-duration: var(--normalTransitionDuration);
  scroll-behavior: smooth;
}
@media (max-width: 720px) {
  html, body, .root{
    min-width: 320px;
  
  }
}