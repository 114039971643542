.mainContainer {
	display: grid;
	justify-items: center;
	padding-top: 50px;
	width: 100%;
}
.title {
	text-align: center;
	font-size: 40px;
	font-weight: 800;
}
.formsContainer {
	width: 100%;
	min-width: var(--minWidthResolution);
	min-width: none;
	display: grid;
	grid-template-columns: 50% 50%;
	padding: 50px;
	padding-top: 20px;
	gap: 50px;
}
.rightFromsContainer {
	width: calc((100vw / 2) - 100px);
	min-width: calc(((var(--minWidthResolution) / 2) * 0.6));
}
.leftFromsContainer {
	display: grid;
	gap: 20px;
}

.sendButton {
	background-color: var(--principalRed);
	color: white;
	font-size: 23px;
	font-weight: 600;
	padding: 10px 90px 10px 90px;
	border-radius: 15px;
}
.sendButton:hover {
	color: var(--principalOrange);
}
.buttonIsDisabled {
	background-color: var(--principalGrey);
	cursor: not-allowed;
	color: var(--principalRed);
	font-size: 23px;
	font-weight: 600;
	padding: 10px 90px 10px 90px;
	border-radius: 15px;
}
.successMessage, .errorMessage {
	color: var(--succesGreen);
	font-weight: 800;
	margin-top: 15px;
	display: flex;
	align-items: center;
	gap: 10px;
}
.errorMessage {
	color: var(--errorRed);
}
.succesIcon svg {
	width: 30px;
	height: 30px;
}

@media (max-width: 720px) {
	.mainContainer{
		display: none;
	}
}