.mainContainer {
    display: grid;
    gap: 20px;
    height: 100%;
}
.messagesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}
.inputsContainer {
    display: grid;
    gap: 20px;
}
.falseInput {
    font-size: 20px;
    display: flex;
    gap: 20px;
    font-weight: 500;
}
.usdPrice {
    font-weight: 800;
}
.inputCheck {
    margin: 2px 0 0;
    line-height: normal;
    width: 20px;
    height: 20px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    position: relative;
}

/* Estilo cuando el checkbox está marcado */
.inputCheck:checked {
    background-color: var(--principalOrange);
    border-color: var(--principalOrange);
}
.inputCheck:checked::after {
    content: "";
    position: absolute;
    top: 2px;
    left: 6px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.successMessage,
.errorMessage {
    color: var(--succesGreen);
    font-weight: 800;
    margin-top: 15px;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 20px 0 20px;
    gap: 10px;
}
.errorMessage {
    color: var(--errorRed);
}
.succesIcon svg {
    width: 30px;
    height: 30px;
}
