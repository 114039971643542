.mainContainer{
    display: grid;
}
.title{
    font-size: 25px;
}
.rmInput {
    background-color: white;
    border: none;
    font-size: 21px;
    padding: 15px;
    outline: none;
    -moz-appearance: textfield;
    appearance: texfield;
    background-color: var(--principalGrey);
}::-webkit-outer-spin-button, ::-webkit-inner-spin-button{
    -webkit-appearance: none;
}


.rmInput:focus{
    outline: none;
}