.mainContainer{
    display: grid;
    justify-items: center;
    text-align: center;
}
.icon{
    height: 90px;
    margin: 0 0 10px 0;
}

.title{
    font-size: 25px;
    font-weight: 800;
}
.subTitle{
    line-height: 18px;
    font-size: 15px;
    width: 80%;
}

@media (max-width: 720px) {
    .mainContainer{
        gap: 5px;
    }
    .title{
        font-size: 22px;
        line-height: 22px;
        height: 45px;
    }
    .subTitle{
        font-size: 15px;
    }

}