.mainContainer {
    display: grid;
    justify-items: center;
    margin-top: 40px;
    width: 100%;
    gap: 50px;
    display: flex;
    justify-content: center;
    justify-items: center;
    flex-direction: column;
}

.containerCard {
    border-radius: 25px;
    cursor: default;
    background-color: var(--specialProductsBackground);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 25px;
    filter: drop-shadow(0px 0 0.5rem rgba(0, 0, 0, 0.5));
}

.mainContainer:hover {
    transform: scale(1.05);
}

.dataContainer {
    display: none;
}
.crownContainer {
    height: 0px;
    display: flex;
    justify-content: right;
    z-index: 1;
    position: relative;
    background-color: red;
    bottom: 25px;
}
.crown {
    height: 50px;
}

.description {
    font-size: 15px;
    font-weight: 100;
    text-align: center;
    line-height: 18px;
    padding-left: 10px;
    padding-right: 10px;
}
.price {
    margin: 35px 0 35px 0;
    font-size: 20px;
    font-weight: 500;
    background-color: var(--principalOrange);
    cursor: default;
    width: fit-content;
    padding: 10px 30px 10px 30px;
    border-radius: 20px;
    white-space: nowrap;
}
.pointsList {
    height: 150px;
}
.pointContainer {
    display: flex;
    flex-direction: column;
    font-size: 21px;
    align-items: center;
    margin-bottom: 20px;
}
.checkImg {
    padding-top: 0px;
    height: 32px;
}

.hireButton {
    font-size: 27px;
    padding: 10px;
    font-weight: 800;
    height: 52px;
    border-radius: 0px 0px 20px;
}
.descriptionCarrousel {
    display: none;
}
.descriptionDesktop {
    display: flex;
    padding: 0px 40px 0px 40px;
    gap: 20px;
}
.infoContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
    width: 22vw;
}
.plusDesktop {
    height: 100%;
    color: var(--principalOrange);
    font-weight: 800;
    font-size: 30px;
    display: flex;
    align-items: center;
}

.title {
    font-size: 20px;
    font-weight: 700;
    min-width: 0px;
    padding: 0 20px 0 20px;
    text-align: center;
}

.infoMainContainerDesktop {
    display: flex;
}

.cardsContainer {
    display: flex;
    width: 100%;
    justify-content: space-around;
    padding: 0px 80px 0 80px;
}

.cardsContainerMobile {
    display: none;
}

.mobileCarousel {
    display: none;
}

@media (max-width: 720px) {
    .descriptionDesktop {
        display: none;
    }

    .pointsList {
        height: min-content;
    }

    .dataContainer {
        padding: 20px;
        display: block;
    }

    .price {
        margin: 15px 0 15px 0;
        background-color: var(--orange200);
    }
    .arrowDisabled {
        opacity: 0;
    }

    .descriptionCarrousel {
        padding-top: 20px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        align-content: center;
        background-color: var(--orange100);
        padding-bottom: 20px;
    }
    .title {
        color: var(--principalOrange);
    }

    .mainContainer:hover {
        transform: none;
    }

    .containerCard {
        width: calc(100vw - 50px);
        max-width: none;
        display: grid;
        justify-items: center;
    }

    .displayName {
        font-size: 30px;
        font-weight: 700;
        color: var(--principalRed);
    }

    .mainContainer {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        justify-items: center;
    }

    .cardsContainer {
        padding: 0px;
    }

    .cardsContainer {
        display: none;
    }

    .mobileCarousel {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
    }
    .cardsContainerMobile {
        display: inline;
        width: calc(100vw - 70px - 45px - 45px);
    }
    .plus {
        font-size: 40px;
        font-weight: 700;
        color: var(--principalOrange);
    }

    .arrowContainer {
        height: min-content;
    }

    .arrow {
        color: var(--principalOrange);
        padding: 10px 0 10px 0;
        border-radius: 10px;
    }

    .pressedButton {
        background-color: var(--principalGrey);
    }
}
