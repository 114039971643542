.mainColumn{
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    min-width: var(--minWidthResolution);
}
@media (max-width: 720px) {
    .mainColumn{
        min-width: 320px;
    }
}