.mainContainer {
	position: relative;
	height: 850px;
	width: 100%;
	display: flex;
	flex-direction: column;
	row-gap: 200px;
}

@media (max-width: 720px) {
	.mainContainer{
		display: none;
	}
}