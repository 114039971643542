.mainContainer{
    display: grid;
    justify-items: center;
    margin-top: 60px;
    width: 100%;
    gap: 50px;
}
.title{
    font-size: 38px;
    font-weight: 700;
    width: 60vw;
    min-width: calc(var(--minWidthResolution) * .60);
    text-align: center;
    line-height: 45px;
}
.cardsContainer{
    display: flex;
    width: 100%;
    justify-content: space-around;
    padding: 0px 80px 0 80px;
}
.cardsContainerMobile{
    display: none;
}
.mobileCarousel{
    display: none;
}

@media (max-width: 720px) {
    .mainContainer{
        display: block;
        margin-top: 30px;
    }
    .cardsContainer{
        padding: 0px ;
    }
    .cardsContainer{
        display: none;
    }
    .mobileCarousel{
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
    }
    .cardsContainerMobile{
        display: inline;
        width: calc(100vw - 120px);
    }
    .title{
        font-size: 30px;
        min-width: 0px;
        width: 100%;
        padding: 0 20px 0 20px;
        justify-content: center;
        display: block;
        color: var(--principalOrange);
    }
    .arrowContainer{
        display: flex;
        align-items: center;
    }
    .arrow{
        color: var(--arrowGrey);
        height: min-content;
        padding: 10px 0 10px 0;
        border-radius: 10px;
    }
    .pressedButton{
        background-color: var(--principalGrey);
    }
}