.mainContainer{
    position: fixed;
    top: 20px;
    cursor: pointer;
    left: -10px;
    z-index: 5;
}
.logo{
    height: 70px;
}

.mainContainer:hover{
    left: 0;
}

@media (max-width: 720px) {
    .logo{
        height: 60px;
    }
}