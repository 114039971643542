.mainContainer{
    margin-top: 50px;
    background-image: url(../../assets/images/ContactInfoBackground.png);
    background-size: cover;
    padding: 40px 40px 40px 40px;
    display: flex;
    justify-content: space-around;
}

@media (max-width: 720px) {
    .mainContainer{
        display: grid;
        gap: 30px;
    }
  }