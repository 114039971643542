.transition {
    transition-duration: var(--popUpTransitionDuration);
}
.mainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 2;
    overflow: scroll;
    width: 100vw;
    min-width: var(--minWidthResolution);
    height: 100%;
    width: 100vw;
    overflow-y: hidden;
    overflow-x: hidden;
}
.backGround {
    top: 0;
    width: 100vw;
    min-width: var(--minWidthResolution);
    height: 100vh;
    background-color: white;
    opacity: 0.5;
    position: fixed;
}
.popUp {
    background-size: 1000px;
    margin: 50px;
    padding: 30px;
    width: 50vw;
    min-width: calc(var(--minWidthResolution) / 2);
    overflow: scroll;
    min-height: 500px;
    height: 70vh;
    background-color: white;
    border-radius: 30px;
    filter: drop-shadow(0px 0px 0.7rem black);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    overflow-x: hidden;
}
.popUp::-webkit-scrollbar {
    display: none;
}
.popUpMobile {
    margin-top: 35vh;
    margin-bottom: 75px;
    margin-left: 75px;
    margin-right: 75px;
    padding: 30px;
    width: 50vw;
    min-width: calc(var(--minWidthResolution) / 2);
    overflow: scroll;
    min-height: 350px;
    background-color: white;
    border-radius: 30px;
    filter: drop-shadow(0px 0px 0.7rem black);
    position: absolute;
}
.popUpMobile {
    margin: 50px;
    padding: 30px;
    width: 50vw;
    min-width: calc(var(--minWidthResolution) / 2);
    overflow: scroll;
    min-height: 350px;
    background-color: white;
    border-radius: 30px;
    filter: drop-shadow(0px 0px 0.7rem black);
}
.inputsContainer {
    width: 100%;
    display: grid;
    gap: 20px;
}
.sendButton {
    font-size: 27px;
    padding: 10px;
    font-weight: 800;
    width: 200px;
    border-radius: 5px;
}

.notScrolling {
    overflow-y: hidden;
}
.scrolling {
    overflow-y: scroll;
}

.paypalContainer {
    margin-top: 40px;
}
.closePopupX {
    display: none;
}
.backGroundResumatio {
    background-image: url(../../assets/images/ContactInfoBackground.png);
    background-repeat: repeat;
    background-size: contain;
}

@media (max-height: 500px) {
    .mainContainer {
        align-items: baseline;
        overflow: scroll;
    }
}

@media (max-width: 720px) {
    .popUp {
        min-width: 0px;
    }
    .mainContainer {
        min-width: 0px;
        z-index: 20;
    }
    .backGround {
        min-width: 0px;
    }
    .popUpMobile {
        min-width: 0px;
        min-height: 0px;
        margin: 0px;
        width: 100vw;
        height: 100vh;
        display: grid;
        align-items: center;
        border-radius: 0px;
        padding: 70px 30px 30px 30px;
    }
    .closePopupX {
        display: flex;
        background-color: var(--errorRed);
        color: white;
        z-index: 300;
        padding: 10px;
        border-radius: 7px;
        font-size: 20px;
        position: absolute;
        top: 20px;
        right: 20px;
    }
}
