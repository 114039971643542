
.mainContainer{
    display: none;
}
@media (max-width: 720px) {
    .mainContainer{
        display: block;
    }
    .tryMenuContainer{
        border-radius: 100%;
        z-index: 4;
        width: 50px;
        height: 50px;
        bottom: 10px;
        left: 10px;
        background-color: var(--blueResumatio);
        overflow: hidden;
        position: absolute;
        display: flex;
        justify-content: left;
        align-items: center;
        position: fixed;
    }
    .tryMenuExpanded{
        overflow: hidden;
        z-index: 4;
        position: fixed;
        border-radius: 0px;
        width: 101vw;
        height: 100vh;
        bottom: 0px;
        left: 0px;
    }

    .specialProductsTag{
        color: var(--principalOrange);
    }

    .tryMenuOff{
        z-index: 4;
        font-size: 35px;
        color: white;
        display: grid;
        font-weight: 700;
        opacity: 0;
        padding: 0 0 0 50px;
    }
    .tryMenu{
        opacity: 1;
        z-index: 4;
        top: 0px;
        left: 0px;
        gap: 10px;
    }
    .menuIcon{
        color: white;
        position: absolute;
        left: 17px;
        bottom: 17px;
        opacity: 1;
    }
    .iconOff{
        opacity: 0;
    }
    
    .closeButtonOff{
        display: none;
        opacity: 0;
        position: fixed;
        bottom: 20px;
        left: 20px;
        font-size: 35px;
        color: wheat;
        z-index: 50;
    }
    .closeButtonOn{
        display: block;
        opacity: 1;
        z-index: 50;
    }
}
