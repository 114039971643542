:root {
	--initial-height: 8vh;
	--background-position: center 0px;
	--height-Media700px: 160px;
	--backgroundPosition-Media700px: center -10px;
	--backgroundPosition-Media850px: center -30px;
	--backgroundPosition-Media1350px: center -50px;
	--backgroundPosition-Media1500px: center -70px;
	--backgroundPosition-Media1750px: center -100px;
	--backgroundPosition-Media2000px: center -130px;
}
.navBarContainer {
	background-image: url("/src/assets/images/Header_Background.png");
	background-size: cover;
	background-position: var(--background-position);
	background-repeat: no-repeat;
	width: 100%;
	height: var(--initial-height);
	position: relative;
}

.navBar {
	display: flex;
	justify-content: flex-end;
	position: relative;
	width: 100%;
}

.navBarButtonComponent, .specialProductsTag {
	padding: 37px 0 10px 0;
	cursor: pointer;
	display: flex;
	font-weight: 600;
	font-size: 21px;
	border-bottom-width: 1.5px;
	border-color: var(--principalBlack);
	transition-duration: var(--normalTransitionDuration);
}

.navBarButtonComponent:hover, .specialProductsTag:hover {
	border-color: var(--principalWhite);
}

.navBarButton {
	color: var(--principalBlack);
	transition-duration: var(--normalTransitionDuration);
}

.navBarButtonComponent:hover .navBarButton {
	color: white;
}

.navBarButtonBuddy {
	width: 25px;
}

.specialProductsTag{
	margin-right: 50px;
	color: var(--specialProductsBackground);
}

.navBarExtraLine {
	width: 85px;
	border-bottom-width: 1.5px;
	border-color: var(--principalBlack);
}
@media (max-width: 720px) {
	.navBar{
		display: none;
	}
	.navBarContainer{
		height: 50vh;
		background-size: cover;
		background-image: url("/src/assets/images/Portada2.png");
	}
}
@media (min-width: 700px) {
	.navBarContainer {
		background-position: var(--backgroundPosition-Media700px);
		height: var(--height-Media700px);
	}
}
@media (min-width: 850px) {
	.navBarContainer {
		background-position: var(--backgroundPosition-Media850px);
	}
}
@media (min-width: 1350px) {
	.navBarContainer {
		background-position: var(--backgroundPosition-Media1350px);
	}
}
@media (min-width: 1500px) {
	.navBarContainer {
		background-position: var(--backgroundPosition-Media1500px);
	}
}
@media (min-width: 1750px) {
	.navBarContainer {
		background-position: var(--backgroundPosition-Media1750px);
	}
}
@media (min-width: 2000px) {
	.navBarContainer {
		background-position: var(--backgroundPosition-Media2000px);
	}
}
