.mainContainer {
	height: 95vh;
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	z-index: 1000;
}

.smallLoader {
	width: 30px;
	margin: 0 auto;
	aspect-ratio: 1;
	border-radius: 50%;
	border: 8px solid;
	border-color: var(--principalOrange) #0000;
	animation: l1 1s infinite;
}
.loader {
	width: 50px;
	margin: 0 auto;
	aspect-ratio: 1;
	border-radius: 50%;
	border: 8px solid;
	border-color: var(--principalOrange) #0000;
	animation: l1 1s infinite;
}
@keyframes l1 {
	to {
		transform: rotate(0.5turn);
	}
}
