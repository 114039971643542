.mainContainer {
	display: grid;
	justify-items: center;
    gap: 30px;
	padding-top: 50px;
}
.title {
	text-align: center;
	font-size: 40px;
	font-weight: 800;
    color: var(--principalOrange);
}
.formsContainer {
	width: calc(100vw - 60px);
    display: flex;
    flex-direction: column;
	padding-top: 20px;
	gap: 20px;
}


.sendButton,.buttonIsDisabled {
    background-color: var(--principalRed);
	color: white;
	font-size: 23px;
	font-weight: 600;
	padding: 10px 90px 10px 90px;
	border-radius: 15px;
    margin-top: 50px;
}
.buttonIsDisabled {
    background-color: var(--principalGrey);
}
.sendButton:hover {
    color: var(--principalOrange);
}
.successMessage, .errorMessage {
	color: var(--succesGreen);
	font-weight: 800;
	margin-top: 15px;
	display: flex;
	align-items: center;
	gap: 10px;
}
.errorMessage {
	color: var(--errorRed);
}
.succesIcon svg {
	width: 30px;
	height: 30px;
}