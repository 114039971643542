.mainContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.mainContainerEmpty {
    height: 100%;
}

.cardContainer {
    margin: 15px 0 0 0;
    filter: drop-shadow(0 5px 0.4rem rgba(0, 0, 0, 0.5));
    background-color: var(--principalGrey);
    padding: 10px 1vw 10px 1vw;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
}
.factsContainer {
    display: grid;
}
.name {
    font-size: 20px;
    font-weight: 500;
}
.userInfo {
    display: flex;
    gap: 10px;
}
.shoppIcon {
    color: var(--principalRed);
    font-size: 35px;
}
.downloadIcon {
    color: var(--principalRed);
    font-size: 35px;
    cursor: pointer;
}

.orderIdDesktop,
.orderIdMobile {
    font-size: 15px;
    font-weight: 800;
}

.orderIdMobile {
    display: none;
}

.emptyMainContainer {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
}

.emptySalesTitle {
    font-size: 25px;
}

@media (max-width: 720px) {
    .mainContainer {
        padding: 30px 0 0 0;
        height: 100%;
    }
    .cardContainer {
        flex-direction: column;
        align-items: start;
        padding-left: 10px;
    }
    .orderIdMobile {
        display: inline;
        margin-top: 8px;
    }
    .orderIdDesktop {
        display: none;
    }
}
