.mainContainer{
    position: fixed;
    bottom:  0;
    right:  0;
    width: 50px;
    height: 50px;
    margin: 0 20px 30px 0;
	transition-duration: var(--normalTransitionDuration);
    cursor: pointer;
    filter: drop-shadow(3px 3px 0.2rem rgb(33, 33, 33));
}
.mainContainer:hover{
    margin: 0 15px 25px 0;
    width: 60px;
    height: 60px;
}
.invisible{
    opacity: 0;
    pointer-events: none;
    cursor: default;
}
.visible{
    opacity: 100;
}

@media (max-width: 720px) {
    .mainContainer{
        margin: 0;
        bottom: 10px;
        right: 10px;
    }
    .mainContainer:hover{
        width: 50px;
        height: 50px;
        margin: 0;
    }
}