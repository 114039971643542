.containerCard {
	border-radius: 40px 40px 10px 10px;
	cursor: default;
	background-color: white;
	max-width: 320px;
	overflow: hidden;
}
.mainContainer {
	margin-top: 40px;
	padding-top: 30px;
	background-color: var(--lightOrange);
	display: grid;
	justify-content: center;
}
.dataContainer {
	display: flex;
	justify-content: center;
	padding: 20px;
}

.displayName{
	font-size: 30px;
	font-weight: 700;
	color: var(--principalRed);
}
.crownContainer {
	height: 0px;
	display: flex;
	justify-content: right;
	z-index: 1;
	position: relative;
	background-color: red;
	bottom: 25px;
}
.crown {
	height: 50px;
}
.title {
	font-size: 25px;
	font-weight: 700;
	text-align: center;
	color: var(--principalOrange);
}
.description {
	height: 100px;
	font-size: 15px;
	font-weight: 100;
	text-align: center;
	line-height: 18px;
}
.price {
	margin: 15px 0 15px 0;
	font-size: 33px;
	font-weight: 700;
	background-color: var(--principalOrange);
	cursor: default;
	width: 90%;
	border-radius: 20px;
	white-space: nowrap;
}
.pointsList {
	height: 150px;
}
.pointContainer {
	display: flex;
	font-size: 21px;
	align-items: flex-start;
}
.checkImg {
	padding-top: 0px;
	height: 32px;
}

.hireButton {
	font-size: 27px;
	padding: 10px;
	font-weight: 800;
	height: 52px;
}

@media (max-width: 720px) {
	.dataContainer{
		display: none;
	}
	.description {
		height: 150px;
	}
	.pointsList {
		height: min-content;
	}
	.mainContainer:hover {
		transform: none;
	}
	.dataContainer{
		min-height: calc(50vh - 90px);
	}
	.description{
		height: min-content;
	}
	.containerCard{
		width: calc(100vw - 50px);
		max-width: none;
	}

}

@media (max-width: 995px) and (min-width: 720px) {
	.pointsList {
		height: 185px;
	}
}
