
.title{
    font-size: 25px;
}
.textBox{
    background-color: var(--principalGrey);
    border: none;
    font-size: 21px;
    padding: 10px;
    outline: none;
    resize: none;
    width: 100%;
    height: calc(100% - 37px);
    display: block;
    margin-right: 1000px;
}
.textBox:focus{
    outline: none;
}

@media (max-width: 720px) {


    .textBox{
        height: 200px;
    }
}