@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	overflow-x: hidden; /* Hide horizontal scrollbar */
  }
.ant-card-head {
	@apply !border-b-0;
}
.ant-card-head-title {
	@apply text-2xl font-bold;
}
.ant-card-body {
	@apply !p-0 flex flex-col h-full;
}
.hire-card-button {
	@apply w-full mt-auto py-1.5 rounded-b-lg text-2xl bg-[#812200] text-white hover:text-[#ff7400];
}
/* Row (header + body) */
.ant-collapse-item {
	@apply flex flex-col items-center justify-center bg-white;
}
.ant-collapse-header{
	width: 100%;
}
/* Row header */
.ant-collapse-header-text {
	@apply text-[#ff7400] font-semibold text-xl;
	text-align: center
}
.anticon.anticon-caret-right.ant-collapse-arrow svg {
	@apply h-5 w-5 mt-2 text-red-900;
}
/* Row Body */
.ant-collapse-content-box {
	@apply !pt-2;
}

.spacer{
	height: 1000px;
}

